<template>
  <div>
    <p id="Text1">
      Every LEGO® Universe world features unique animals-- and you can tame nearly twenty types of creatures to make them your pets!
    </p>
    <p>
      Hop in your custom rocket and blast off to a pet-taming world to begin learning the ways of the wild beasts! Once you’ve tamed a pet, you can make it “speak,” bow or stick by your side through all your LEGO Universe adventures!
    </p>
    <!-- pic5658854E67DF998A3EE5B72DAB2097D8.jpg -->
    <img class="rounded" src="@/assets/news-network/lu-pets-1.jpg">
    <p><i>Minifigure with pet crocodile</i></p>
    <p>
      And your pets can help you advance in the game! Get your critter to dig up buried treasure or turn on a bouncer that blasts you to the top of a massive mountain!
    </p>
    <!-- picBC8FDB648A1910B477D731B2E5FF28BF.jpg -->
    <img class="rounded" src="@/assets/news-network/lu-pets-2.jpg">
    <p><i>Pet cat digging up treasure for a minifigure</i></p>
    <p>
      As you explore LEGO Universe you can tame all the types of creative pet: Dogs, warthogs, dragons and many more-- only in the first massively multiplayer online game (MMOG) for LEGO fans!
    </p>
  </div>
</template>
